import { useCoin } from '@hooks/use-coins';
import { swapCoinModel } from '@models/swap-coin.model';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { useModel } from 'foca';
import { type FC, memo } from 'react';
import styles from './exchange-rate.module.scss';
import { type BestSwapRouterType } from '@hooks/use-swap-route';
import { Decimal } from 'turbos-clmm-sdk';
import { nativeCoinModel } from '@models/native-coin.model';

interface OwnProps {
  amountIn: string | undefined;
  amountOut: string | undefined;
  bestRoutes: BestSwapRouterType[] | undefined;
  isDeepbook: boolean;
}

const ExchangeRate: FC<OwnProps> = ({ bestRoutes, amountIn, amountOut, isDeepbook }) => {
  const { coinTypeA, coinTypeB } = useModel(isDeepbook ? nativeCoinModel : swapCoinModel);
  const coinA = useCoin(coinTypeA);
  const coinB = useCoin(coinTypeB);

  if (!coinA || !coinB || !bestRoutes || bestRoutes.length < 1 || !amountIn || !amountOut)
    return null;

  let price = new Decimal(amountOut)
    .mul(10 ** coinA.decimals)
    .div(new Decimal(amountIn).mul(10 ** coinB.decimals));

  return (
    <div className={styles.best_route}>
      <span>Best Route:&nbsp;</span>
      <span>
        1&nbsp;{coinA.symbol}&nbsp;≈&nbsp;{bigNumberToReadable(price, 0)}
        &nbsp;{coinB.symbol}
      </span>
    </div>
  );
};

export default memo(ExchangeRate);
