import { type FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from './top.module.scss';
// import { swapCoinModel } from '@models/swap-coin.model';
import { useNavigate } from 'react-router-dom';

export const Top: FC = () => {
  const navigator = useNavigate();

  const banners = [
    // {
    //   className: styles.blub,
    //   clickEvent: () => {
    //     navigator(
    //       '/pools/0x6a3be30a31f88d9055da7f26f53dd34c85bc5aab9028212361ccf67f5f00fd46/add-liquidity',
    //     );
    //   },
    // },
    // {
    //   className: styles.puff,
    //   clickEvent: () => {
    //     // swapCoinModel.updateB(
    //     //   '0xe9a376ada59b2ad927eaedc086dd0d7649b56433598ceda4f9d86ab95309e651::puff::PUFF',
    //     // );
    //     // swapCoinModel.updateA('0x2::sui::SUI');
    //     navigator(
    //       '/pools/0x74b335d489c325b1a49d81eb50f75bd9b2393282313c65933215616640f4430f/add-liquidity',
    //     );
    //   },
    // },
    // {
    //   className: styles.std,
    //   clickEvent: () => {
    //     // swapCoinModel.updateB(
    //     //   '0xe9a376ada59b2ad927eaedc086dd0d7649b56433598ceda4f9d86ab95309e651::puff::PUFF',
    //     // );
    //     // swapCoinModel.updateA('0x2::sui::SUI');
    //     navigator(
    //       '/pools/0x5034890f4a8637aba56b48b3945be28d5e8065b48d858332ff499a80701d9796/add-liquidity',
    //     );
    //   },
    // },
    // {
    //   className: styles.liq,
    //   clickEvent: () => {
    //     swapCoinModel.updateB(
    //       '0x9c86d1926a0a39e906f20674d6a35f337be8625ebcb6b799ee8ff011f328bee2::liq::LIQ',
    //     );
    //     swapCoinModel.updateA('0x2::sui::SUI');
    //     navigator(
    //       '/trade?input=0x2::sui::SUI&output=0x9c86d1926a0a39e906f20674d6a35f337be8625ebcb6b799ee8ff011f328bee2::liq::LIQ',
    //     );
    //   },
    // },
    // {
    //   className: styles.wif,
    //   clickEvent: () => {
    //     // swapCoinModel.updateB(
    //     //   '0xe9a376ada59b2ad927eaedc086dd0d7649b56433598ceda4f9d86ab95309e651::puff::PUFF',
    //     // );
    //     // swapCoinModel.updateA('0x2::sui::SUI');
    //     navigator(
    //       '/pools/0x4b6c2b4ac06ceaa513520e1b99cdf92386c812beb7d3890b0dbb170d73a970aa/add-liquidity',
    //     );
    //   },
    // },
    // {
    //   className: styles.babyblub,
    //   clickEvent: () => {
    //     // swapCoinModel.updateB(
    //     //   '0xe9a376ada59b2ad927eaedc086dd0d7649b56433598ceda4f9d86ab95309e651::puff::PUFF',
    //     // );
    //     // swapCoinModel.updateA('0x2::sui::SUI');
    //     navigator(
    //       '/pools/0xa37ad2160ccd972caf2e65613435f0603d12d54d104ba6d1665c85ce7ae3194e/add-liquidity',
    //     );
    //   },
    // },
    {
      className: styles.fud,
      clickEvent: () => {
        // swapCoinModel.updateB(
        //   '0xe9a376ada59b2ad927eaedc086dd0d7649b56433598ceda4f9d86ab95309e651::puff::PUFF',
        // );
        // swapCoinModel.updateA('0x2::sui::SUI');
        navigator(
          '/pools/0xbd85f61a1b755b6034c62f16938d6da7c85941705d9d10aa1843b809b0e35582/add-liquidity',
        );
      },
    },
    // {
    //   className: styles.ape,
    //   clickEvent: () => {
    //     // swapCoinModel.updateB(
    //     //   '0xe9a376ada59b2ad927eaedc086dd0d7649b56433598ceda4f9d86ab95309e651::puff::PUFF',
    //     // );
    //     // swapCoinModel.updateA('0x2::sui::SUI');
    //     navigator(
    //       '/pools/0x54fff14675a40a1fc5dbbad129c01cba7275ad429fc7c25d44439586e2339d93/add-liquidity',
    //     );
    //   },
    // },

    {
      className: styles.turbos_sui,
      clickEvent: () => {
        // swapCoinModel.updateB(
        //   '0xe9a376ada59b2ad927eaedc086dd0d7649b56433598ceda4f9d86ab95309e651::puff::PUFF',
        // );
        // swapCoinModel.updateA('0x2::sui::SUI');
        navigator(
          '/pools/0x2c6fc12bf0d093b5391e7c0fed7e044d52bc14eb29f6352a3fb358e33e80729e/add-liquidity',
        );
      },
    },

    // {
    //   className: styles.turbos_usdc,
    //   clickEvent: () => {
    //     // swapCoinModel.updateB(
    //     //   '0xe9a376ada59b2ad927eaedc086dd0d7649b56433598ceda4f9d86ab95309e651::puff::PUFF',
    //     // );
    //     // swapCoinModel.updateA('0x2::sui::SUI');
    //     navigator(
    //       '/pools/0x51ee9f5e33c1d7b38b197a09acb17ef0027e83e6d0b3c0f6466855398e4c1cba/add-liquidity',
    //     );
    //   },
    // },
  ];

  const finalBalances = banners.sort(() => Math.random() - 0.5);
  return (
    <div className={styles.top}>
      <Swiper
        modules={[Autoplay, Pagination]}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
      >
        <SwiperSlide>
          <div
            className={`${styles.slide} ${styles.usdc}`}
            onClick={() => {
              navigator(
                '/pools/0x770010854059edf1dd3d49a97f3054c39b870ec708fe2f408e30a8ef4724caef/add-liquidity',
              );
            }}
          ></div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className={`${styles.slide} ${styles.usdc_sui}`}
            onClick={() => {
              navigator(
                '/pools/0x77f786e7bbd5f93f7dc09edbcffd9ea073945564767b65cf605f388328449d50/add-liquidity',
              );
            }}
          ></div>
        </SwiperSlide>
        {finalBalances.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={`${styles.slide} ${item.className}`} onClick={item.clickEvent}></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
