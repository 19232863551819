import { useModel } from 'foca';
import { swapCoinModel } from '@models/swap-coin.model';
import { useEffect, useState } from 'react';
import { poolModel, type PoolSwapRoute } from '@models/pool.model';
import { turbosSdk } from 'src/services/turbos-sdk';
import { useAddress } from './use-address';
import { Decimal, Trade } from 'turbos-clmm-sdk';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { useSwapInsufficientLiquidity } from './use-swap-insufficient-liquidity';
import { Transaction } from '@mysten/sui/transactions';
import { deepbook } from '@libs/deepbook';
import { type DeepbookRouter } from '@libs/deepbook-sdk';
import { useCoin } from './use-coins';
import { useCoinTypeMin } from './use-coin-type-min';
import { useRefreshFlag } from './use-refresh';
import { type TurbosCoinItem } from '@models/coin.model';
import { isEqual } from 'lodash-es';
import { nativeCoinModel } from '@models/native-coin.model';

interface DeepbookRouterType {
  isBid?: boolean;
  deepbook?: boolean;
  txb?: Transaction;
  deepbookPrice?: string;
  deepbookFee?: string;
}

interface TurbosBestRouteResult {
  pool: PoolSwapRoute;
  swapResult: Trade.ComputedSwapResult;
  amountIn: string;
  amountOut: string;
  nextTickIndex: number;
}

export interface BestSwapRouterType extends DeepbookRouterType {
  pool?: PoolSwapRoute;
  swapResult?: Trade.ComputedSwapResult;
  amountIn: string;
  amountOut: string;
  nextTickIndex: number;
  atob?: boolean;
  coinTypeA?: string;
  coinTypeB?: string;
}

interface PreData {
  coinTypeA: string;
  coinTypeB: string;
  coinA: TurbosCoinItem | undefined;
  coinB: TurbosCoinItem | undefined;
  amount: string;
  amountSpecifiedIsInput: boolean;
  address: string;
  deepbookActive: boolean | undefined;
  minDecimal: number;
  manual: number;
}

export const useSwapRoute = (
  amount: string | undefined,
  amountSpecifiedIsInput: boolean,
  deepbookActive?: boolean,
) => {
  const { refresh, manual } = useRefreshFlag();
  const [best, setBest] = useState<BestSwapRouterType[]>();
  const [loading, setLoading] = useState(false);
  const [isDeepbook, setIsDeepbook] = useState(false);
  const [transactionBlock, setTransactionBlock] = useState<Transaction | undefined>();
  const address = useAddress();
  const { coinTypeA, coinTypeB } = useModel(deepbookActive ? nativeCoinModel : swapCoinModel);
  const coinA = useCoin(coinTypeA);
  const coinB = useCoin(coinTypeB);
  const { minDecimal } = useCoinTypeMin(coinTypeA);
  const [_, setPrevData] = useState<PreData | undefined>();

  let _address = address || '0xc851a734b97870c41435b06c8254f1ef4cef0d53cfe1bcb0ba21a175b528311e';
  useEffect(() => {
    if (
      !coinTypeA ||
      !coinTypeB ||
      !amount ||
      amount === '0' ||
      !_address ||
      coinB!.decimals === undefined ||
      coinA!.decimals === undefined
    ) {
      setBest(undefined);
      setLoading(false);
      return;
    }

    let opExpired: boolean = false;
    const selectBestPool = async (
      pools: { pool: string; a2b: boolean; amountSpecified: number | string }[],
    ): Promise<Trade.ComputedSwapResult[]> => {
      const swapResults = await new Promise<Trade.ComputedSwapResult[]>((resolve, reject) => {
        let handled = false;
        // Some kind of fullnode are interrupted forever.
        setTimeout(() => {
          handled = true;
          Sentry.captureException('best route request timed out 30s');
          resolve([]);
        }, 30_000);

        turbosSdk.trade
          .computeSwapResultV2({
            pools,
            amountSpecifiedIsInput,
            address: _address,
          })
          .then((data) => {
            handled || resolve(data);
          })
          .catch((e) => {
            if (handled) return;
            // Error: Object runtime cached objects limit (1000 entries) reached
            if ((e as Error).message.includes('MEMORY_LIMIT_EXCEEDED')) {
              resolve([]);
            } else {
              reject(e);
            }
          });
      });
      return swapResults;
    };

    const getBestRoute = async () => {
      const routePools = (
        await poolModel.getBaseRoute(
          coinTypeA,
          coinTypeB,
          amountSpecifiedIsInput,
          new Decimal(amount)
            .div(10 ** (amountSpecifiedIsInput ? coinA!.decimals : coinB!.decimals))
            .toString(),
        )
      ).filter((routePool) => {
        return routePool.pools.some((pool) => pool.liquidity !== '0');
      });

      if (opExpired) return;
      let bestPools: BestSwapRouterType[] = [];

      // 1 route
      const oneRoutes = routePools.map((routePool) => {
        if (routePool.type === 'single-hop') {
          return routePool.pools[0]!;
        } else {
          return routePool.pools[amountSpecifiedIsInput ? 0 : 1]!;
        }
      });

      const oneSwapResults = await selectBestPool(
        oneRoutes.map((route) => ({
          pool: route.pool_id,
          a2b: route.a_to_b,
          amountSpecified: amount,
        })),
      );
      // console.log(oneSwapResults, 'oneSwapResults');
      // filter amount 0
      const zeroAmountPools = oneSwapResults
        .filter((result) => result.amount_a === '0' || result.amount_b === '0')
        .map((item) => item.pool);

      // 2 route
      const doubleHop = routePools.filter(
        (pool) =>
          pool.type !== 'single-hop' &&
          !pool.pools.some((item) => zeroAmountPools.includes(item.pool_id)),
      );

      const twoRoutes = doubleHop.map((hop) => {
        const prevPool = hop.pools[amountSpecifiedIsInput ? 0 : 1]!;
        const currentPool = hop.pools[amountSpecifiedIsInput ? 1 : 0]!;

        const routeDev = oneSwapResults.find((result) => result.pool === prevPool.pool_id)!;
        return {
          pool: currentPool.pool_id,
          a2b: currentPool.a_to_b,
          amountSpecified:
            amountSpecifiedIsInput === routeDev.a_to_b ? routeDev.amount_b : routeDev.amount_a,
        };
      });
      const twoSwapResults = twoRoutes.length > 0 ? await selectBestPool(twoRoutes) : [];
      // console.log(twoSwapResults, 'twoSwapResults', amountSpecifiedIsInput);
      // final route
      let num = 0;
      const finalRoutes = routePools
        .map((routePool, index) => {
          const floor = routePool.type === 'single-hop';
          const pools: PoolSwapRoute[] = amountSpecifiedIsInput
            ? routePool.pools
            : routePool.pools.reverse();

          const results = pools.map((pool, i) => {
            let swapResult;
            if (floor) {
              swapResult = oneSwapResults[i];
            } else {
              if (i === 0) {
                swapResult = oneSwapResults[index];
              } else {
                swapResult = twoSwapResults[num];
                num++;
              }
            }

            // const swapResult = [...oneSwapResults, ...twoSwapResults].find(
            //   (result) => result.pool === pool.pool_id,
            // );

            if (!swapResult || swapResult.amount_a === '0' || swapResult.amount_b === '0') {
              return;
            }

            return {
              pool,
              swapResult,
              amountIn: swapResult.a_to_b ? swapResult.amount_a : swapResult.amount_b,
              amountOut: swapResult.a_to_b ? swapResult.amount_b : swapResult.amount_a,
              nextTickIndex: turbosSdk.math.bitsToNumber(swapResult.tick_current_index.bits),
            };
          });
          return amountSpecifiedIsInput ? results : results.reverse();
        })
        .filter((item) => item.every((val) => !!val)) as TurbosBestRouteResult[][];
      // console.log(finalRoutes);
      // find best
      let prevAmountOut = 0;
      finalRoutes.forEach((finalRoute) => {
        const amountIn = finalRoute[0]!.amountIn;
        const amountOut = finalRoute[finalRoute.length - 1]!.amountOut;
        // AmountIn eq amount
        if (
          (amountSpecifiedIsInput && amountIn !== amount) ||
          (!amountSpecifiedIsInput && amountOut !== amount)
        ) {
          return;
        }

        // first route amountOut eq second route amountIn
        if (finalRoute.length > 1) {
          const inAmount = amountSpecifiedIsInput
            ? finalRoute[0]?.amountOut
            : finalRoute[finalRoute.length - 1]?.amountIn;
          const outAmount = !amountSpecifiedIsInput
            ? finalRoute[0]?.amountOut
            : finalRoute[finalRoute.length - 1]?.amountIn;
          if (Number(inAmount) !== Number(outAmount)) return;
        }

        // When the quantities are equal, take the one with the best liquidity
        const _amountOut = !amountSpecifiedIsInput ? amountIn : amountOut;
        let better = false;
        if (new Decimal(_amountOut).eq(prevAmountOut)) {
          better = finalRoute.length < bestPools.length; // select min route path
        } else {
          better = amountSpecifiedIsInput
            ? new Decimal(_amountOut).gt(prevAmountOut)
            : new Decimal(prevAmountOut).eq(0) || new Decimal(_amountOut).lt(prevAmountOut);
        }

        if (better) {
          prevAmountOut = Number(_amountOut);
          bestPools = finalRoute;
        }
      });
      // console.log(bestPools, 'bestPools');
      if (opExpired) return;
      setBest(bestPools);
      setIsDeepbook(false);
      setTransactionBlock(undefined);
    };

    const getDeepBookRoute = async () => {
      let deepbookData: DeepbookRouter | undefined;
      const bestPools: BestSwapRouterType[] = [];

      const poolA = deepbook.findPool(coinTypeA, coinTypeB);
      const poolB = deepbook.findPool(coinTypeB, coinTypeA);

      if (!poolA && !poolB) return;
      let atob = true;
      if (poolB) {
        atob = false;
      }

      let _amount = new Decimal(new Decimal(amount).div(10 ** coinA!.decimals).toFixed(minDecimal))
        .mul(10 ** coinA!.decimals)
        .toFixed(0);
      _amount = Number(_amount) > Number(amount) ? amount : _amount;
      try {
        if (atob && poolA) {
          deepbookData = await deepbook.dev_inspect_swap_exact_base_for_quote(
            coinTypeA,
            coinTypeB,
            poolA,
            Number(_amount),
            _address,
          );
        } else if (!atob && poolB) {
          deepbookData = await deepbook.dev_inspect_swap_exact_quote_for_base(
            coinTypeB,
            coinTypeA,
            poolB,
            Number(_amount),
            _address,
          );
        } else {
          return;
        }

        const amountIn = new Decimal(deepbookData.amountIn).mul(10 ** coinA!.decimals).toString();
        const amountOut = new Decimal(deepbookData.amountOut).mul(10 ** coinB!.decimals).toString();

        bestPools[0] = {
          swapResult: undefined,
          pool: undefined,
          ...deepbookData,
          amountIn: amountIn,
          amountOut: amountOut,
          atob,
          coinTypeA,
          coinTypeB,
          nextTickIndex: 0,
        };
      } catch (err) {
        Sentry.captureException(err);
      }

      if (opExpired) return;
      setBest(bestPools);
      setIsDeepbook(true);
      setTransactionBlock(deepbookData?.txb ? deepbookData.txb : undefined);
    };

    const timer = setTimeout(async () => {
      try {
        const current = {
          coinTypeA,
          coinTypeB,
          coinA,
          coinB,
          amount,
          amountSpecifiedIsInput,
          address: _address,
          deepbookActive,
          minDecimal,
          manual,
        };
        setPrevData((pre) => {
          if (!isEqual(current, pre)) {
            setLoading(true);
            setBest(undefined);
          }
          return pre;
        });

        if (deepbookActive) {
          await getDeepBookRoute();
        } else {
          await getBestRoute();
        }
        setPrevData(current);
      } catch (e) {
        toast((e as Error).message, { type: 'error' });
        Sentry.captureException(e);
      } finally {
        opExpired || setLoading(false);
      }
    }, 280);

    return () => {
      clearTimeout(timer);
      opExpired = true;
      setIsDeepbook(false);
      setTransactionBlock(undefined);
    };
  }, [
    coinTypeA,
    coinTypeB,
    coinA,
    coinB,
    amount,
    amountSpecifiedIsInput,
    _address,
    deepbookActive,
    minDecimal,
    refresh,
    manual,
  ]);

  const amountIn = amountSpecifiedIsInput ? amount : best?.[0]?.amountIn;
  const amountOut = amountSpecifiedIsInput ? best?.[best.length - 1]?.amountOut : amount;
  const insufficientLiquidity = deepbookActive
    ? !amountOut
    : useSwapInsufficientLiquidity(best, amountIn, amountOut);
  const insufficientBalance = amountSpecifiedIsInput ? amountIn === amount : amountOut === amount;

  return {
    isDeepbook,
    fetchingBestRoute: loading,
    bestRoute: (insufficientLiquidity && !isDeepbook) || !insufficientBalance ? undefined : best,
    amountIn: amountIn === '0' || !insufficientBalance ? undefined : amountIn,
    amountOut: amountOut === '0' || !insufficientBalance ? undefined : amountOut,
    insufficientLiquidity: isDeepbook ? false : insufficientLiquidity,
    transactionBlock,
  };
};
